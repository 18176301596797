<script setup lang="ts">
const {
  menuColumns,
  ctaButtons,
  slogan,
  hasDarkBackground = true,
  logoClass = 'max-w-[80%] md:max-w-[400px] pb-4 md:pb-0',
  poweredBy = {
    text: 'Powered by Volunteerly',
    to: 'https://www.volunteerly.nl',
  },
} = defineProps<{
  menuColumns: {
    name: string
    items: {
      name: string
      to: string
    }[]
  }[]
  ctaButtons?: {
    title: string
    to: string
  }[]
  slogan?: string
  logoClass?: string
  hasDarkBackground?: boolean
  poweredBy?: {
    text: string
    to: string
  }
}>()

// TODO:
// - Mobile menu needs work -> not anymore right?

// TODO: connect to CMS later
// const { columns } = await useFooterItems()
// const row1 = columns.slice(0, 4)
// const hasItemsInFirstRow = row1.some(column => column.items.length > 0)

const { defaultShapes } = useTheme()

const isRounded = computed(() => defaultShapes.value?.card == 'rounded')

// We could make this a config var, but the main reason to make the footer full width
// is that Maud's footer small width design only looks good with rounded corners.
const hasFullWidthBackground = computed(() => !isRounded.value)

</script>

<template>
  <footer class="mt-4" :class="{ 'footer-background': hasFullWidthBackground }">
    <ContentBlockViewPartialContainer padding="none">
      
      
      
      <div
        class="footer-background py-12 text-white md:gap-32 md:px-12"
        :class="{
          'rounded-t-lg': isRounded,
        }"
      >
        <div class="flex flex-col justify-between gap-4 md:flex-row">
          <div class="col-span-1 flex flex-col items-center gap-4 md:items-start ">
            <slot name="logo">
              <PublicLogoDarkMode v-if="hasDarkBackground" :class="logoClass" />
              <PublicLogo v-else :class="logoClass" />
            </slot>

            <BaseParagraph v-if="slogan" size="sm">
              {{ slogan }}
            </BaseParagraph>
          </div>

          
          <div
            v-if="menuColumns.length > 0"
            class="xs:grid-cols-1 ml-4 grid grow gap-x-4 gap-y-8 md:max-w-lg"
            :class="`md:grid-cols-${menuColumns.length}`"
          >
            <div
              v-for="column in menuColumns"
              :key="column.name"
              class="text-center md:text-left"
            >
              <BaseHeading
                size="lg"
                weight="extrabold"
                class="mb-4"
              >
                {{ column.name }}
              </BaseHeading>
              <ul class="flex flex-col gap-2">
                <li v-for="item in column.items" :key="item.name">
                  <NuxtLink :to="item.to">
                    {{ item.name }}
                  </NuxtLink>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <hr class="my-8 h-px w-full border-0 bg-white/50">

        <div class="flex flex-col items-center justify-between gap-8 py-4 md:flex-row md:gap-4">
          
          <nav class="mt-1 text-sm" aria-label="Footer links">
            <ul class="flex w-full space-x-2">
              <li>
                <a
                  :href="poweredBy.to"
                  class="hover:underline focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {{ poweredBy.text }}
                </a>
              </li>
              <li aria-hidden="true">
                |
              </li>
              <li>
                <NuxtLink
                  to="/privacyverklaring"
                  class="hover:underline focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2"
                >
                  Privacy
                </NuxtLink>
              </li>
            </ul>
          </nav>

          <slot name="cta-buttons">
            <nav aria-label="Call to action buttons" class="flex flex-col gap-2 md:flex-row">
              
              <BaseButton
                v-for="button in ctaButtons"
                :key="button.title"
                color="light"
                flavor="outline"
                :to="button.to"
              >
                {{ button.title }}
              </BaseButton>
            </nav>
          </slot>
        </div>
      </div>
    </ContentBlockViewPartialContainer>
  </footer>
</template>

<style scoped>
/* TODO: add to generic styles? It's only in use for the footer currently. */
/* or maybe make it the info color? */

.footer-background {
  background-color: var(--color-theme-footer-background);
}
</style>
